export default {
    home:{
        title1:'項目白皮書',
        title2:'項目影片',
        title3:'項目資訊',
        subtitle:'更多更新敬請期待',
        content1:'即將上線',
        content2:'收起',
        content3:'查看全部',
        content4:'完整版本',
        whitePaperTitle0:'等待共識投票結果',
        whitePaperTitle1:'概述',
        whitePaperTitle2:'理念和目標',
        whitePaperTitle3:'介紹',
        whitePaperTitle4:'BRC-404-BCP 燃燒機會協議',
        whitePaperTitle5:'預售與空投',
        whitePaperTitle6:'代幣經濟學',
        whitePaperTitle7:'Quantum Compass',
        whitePaperTitle8:'Quantum Compute Power',
        whitePaperTitle9:'環體關係網絡與收益分配',
        whitePaperTitle10:'生態系統發展',
        whitePaperTitle11:'SQV生態系統中的自主治理實踐',
        whitePaperTitle12:'Roadmap',
        publicizeTitle1:'1 SQV星能獵手——未來由你定義！',
        publicizeTitle2:'2 Soul Quantum Void—感性與理性之間的未來光芒',
        publicizeTitle3:'3 “起源之種”孵化計劃——助力Soul Quantum Void項目的啟動，見證全新共識MEME生態系統的誕生',
        publicizeTitle4:'4 SQV的核心邏輯 | 代幣經濟學與收益能力的初步探討！',
        publicizeTitle5:'5 QCP - 數字藝術的魔法盒子，探索下一個升值奇跡！',
        publicizeTitle6:'6 無界關繫，SQV收益暴漲的秘密武器！',
    }
   
   }
   