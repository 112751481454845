export default {
   home:{
      title1:'项目白皮书',
      title2:'项目视频',
      title3:'项目信息',
      subtitle:'更多更新敬请期待',
      content1:'即将上线',
      content2:'收起',
      content3:'查看全部',
      content4:'完整版本',
      whitePaperTitle0:'等待共识投票结果',
      whitePaperTitle1:'概述',
      whitePaperTitle2:'理念与⽬标',
      whitePaperTitle3:'介绍',
      whitePaperTitle4:'BRC-404-BCP  燃烧机会协议',
      whitePaperTitle5:'预售与空投',
      whitePaperTitle6:'代币经济学',
      whitePaperTitle7:'Quantum Compass',
      whitePaperTitle8:'Quantum Compute Power',
      whitePaperTitle9:'环体关系网络与收益分配',
      whitePaperTitle10:'⽣态系统发展',
      whitePaperTitle11:'SQV生态系统中的自主治理实践',
      whitePaperTitle12:'Roadmap',
      publicizeTitle1:'1 SQV星能猎手——未来由你定义！',
      publicizeTitle2:'2 Soul Quantum Void—感性与理性之间的未来光芒',
      publicizeTitle3:'3 “起源之种”孵化计划——助力Soul Quantum Void项目的启动，见证全新共识MEME生态系统的诞生',
      publicizeTitle4:'4 SQV的核心逻辑 | 代币经济学与收益能力的初步探讨！',
      publicizeTitle5:'5 QCP - 数字艺术的魔法盒子，探索下一个升值奇迹！',
      publicizeTitle6:'6 关系无界，SQV收益飙升的秘密武器！',
   }
  
  }
  