<template>
  <div class="page">
    <div class="page-main">
      <div class="main">
        <div class="card-main">
          <img
            class="body-img-bg"
            :src="getItemImg(cardItem)"
            :fit="'scale-down'"
          />
        </div>
        <div class="icon-main" @click="clickClose()">   
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCounterStore } from "../stores/pageStore.js";

const downPageStore = useCounterStore();

const { cardItem } = storeToRefs(downPageStore);

//关闭
const clickClose = () => {
  downPageStore.cardModuleState = false;
};

//获取图片
const getItemImg =(item)=>{
let lan = localStorage.getItem("languageStore")

if(lan == 'cj'){
//越南语
return item.VietnameseImg
}
if(lan == 'en'){
  //英语
  return item.whiteImg
}
if(lan == 'kr'){
  //韩语
  return item.KoreanImg
}
if(lan == 'thai'){
  //泰语
  return item.ThaiImg
}
if(lan == 'zh'){
  //中文
  return item.SimChineseImg
}
if(lan == 'zhft'){
  //中文繁体
  return item.TraChineseImg
}
if(lan == 'jpa'){
  //日语
  return item.JpaImg
}
if(lan == 'rl'){
  //俄语
  return item.RLImg
}
}


</script>

<style scoped>
@media screen and (max-width: 1101px) {
 
  .page-main {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 9999;
    overflow: hidden;
  }
  .main{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   
   overscroll-behavior:none ;
  }
  .card-main {
    width: 48.91rem;
    height: 60%;
    background-color: #000;
    overflow: auto;
    overscroll-behavior:none ;
  }
  .icon-main {
    width: 4.53rem;
    height: 4.53rem;
  margin-top: 2rem;
    background-image: url("../assets/white/close-icon.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1101px) {
  .page-main {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 99;
  }
  .main{
    position: relative;
    width: 48.91rem;
    height: 54rem;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .card-main {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 48.91rem;
    height: 58rem;
    background-color: #000;
    overflow: auto;
  }
  .icon-main {
    width: 2.97rem;
    height: 2.97rem;
    position: relative;
    left: 50%;
    bottom: 0rem;
    transform: translateX(-50%);
    background-image: url("../assets/white/close-icon.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.body-img-bg {
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
::-webkit-scrollbar {
  width: 0.2rem;
  /* 设置滚动条的宽度 */
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #050407;
  /* 设置轨道的背景颜色 */
}

/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: #343434;
  /* 设置滑块的背景颜色 */
}
</style>
