import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import './util/rem.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from "./lang"; 




const app = createApp(App);

// 注册 Pinia 插件
const pinia = createPinia();
app.use(pinia);
app.use(ElementPlus)
app.use(i18n);
app.mount('#app');
