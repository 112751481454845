<template>
  <div class="page-bg">
    <HeaderModule></HeaderModule>
    <div class="scall" :class="cardModuleState ? 'card-open' : 'card-close'">
      <div class="page-main" :class="cardModuleState ? 'card-open' : 'card-close'">
        <div class="main-top-title"></div>
        <div class="white-list">
          <div class="title-main">
            <div class="main-title">
              <div class="main-title-left"></div>
              <div class="title-text">{{ $t(titleArray[0].title) }}</div>
              <div class="main-title-right"></div>
            </div>

            <div class="title-tips-text">{{ $t(titleArray[0].tips) }}</div>
          </div>
          <div class="white-list-array" :class="downOpen?'white-list-array-open':'white-list-array-close'">
            <div v-for="(items, index) in downOpen? whiteArray :whiteArray.slice(0,5)" :key="index" @click="clickItem(items)"
              @mousemove="changeMove(items)">
              <div class="item-main-1" :style="{ 'background-image': 'url(' + items.movebg + ')' }">
                <div class="item-grade-text-new mar-left text-shadow-open" v-if="items.id==0" >{{ items.grade }}</div>
                <div class="item-grade-text mar-left" v-else>{{ items.grade }}</div>
                <div class="item-title-text text-shadow-open" :class="items.id == objectId ? 'title-open' : 'title-open'" v-if="items.id==0">{{ $t(items.title) }}</div>
                <div class="item-title-text" :class="items.id == objectId ? 'title-open' : ''" v-else>{{ $t(items.title) }}</div>
              </div>
            </div>
            <div class="upload-main" v-if="whiteArray.length>5" @click="clickLoadButton()">
              <div class="upload-img-main" @click="clickUpLoad()">
                <div class="upload-icon" :class="LoadRef?'opcit-open':''" ></div>
          <div class="upload-text" :class="LoadRef?'title-open':''">{{ $t('home.content4')}}</div>
              </div>
              <div class="down-main" @click="clickDown()"><div class="down-text">{{downOpen? $t('home.content2'): $t('home.content3') }}</div> <div class="down-icon" :class="downOpen?'down-icon-open':''"></div></div>
            </div>
          </div>
        </div>
        <div class="video-list">
          <div class="title-main">
            <div class="main-title">
              <div class="main-title-left"></div>
              <div class="title-text">{{ $t(titleArray[1].title) }}</div>
              <div class="main-title-right"></div>
            </div>

            <div class="title-tips-text">{{ $t(titleArray[1].tips) }}</div>
          </div>
          <div class="video-main">
            <div class="video-list-item">
              <swiper class="slider" slidesPerView="auto" @slideChange="changeArray">
                <swiper-slide v-for="(item, index) in videoArray" @click="clickVideo(index)" :key="index">
                  <div class="lite-item">
                    <div class="video-item">
                      <iframe width="100%" height="100%" :src="item.url" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <!-- <div class="carousel">
              <div class="carousel-list">
                <div
                  :class="
                    item.id == activeIndex
                      ? 'carousel-item-true'
                      : 'carousel-item'
                  "
                  v-for="(item, index) in videoArray"
                  :key="index"
                ></div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="publicize-class">
          <div class="title-main">
            <div class="main-title">
              <div class="main-title-left"></div>
              <div class="title-text">{{ $t(titleArray[2].title) }}</div>
              <div class="main-title-right"></div>
            </div>

            <div class="title-tips-text">{{ $t(titleArray[2].tips) }}</div>
          </div>
          <div class="publicize-main">
            <div class="publicize-item" v-for="(item, index) in publicizeArray" :key="index"
              :style="{ 'background-image': 'url(' + item.moveBgimg + ')' }" @click="clickItemPub(item)">
              <div class="pub-icon" :style="{ 'background-image': 'url(' + item.icon + ')' }"></div>
              <div class="pub-title" :class="objectIdPub==item.id?'title-open':''">{{ $t(item.title) }}</div>
            </div>
          </div>
        </div>
        <div class="footer-main">
          <div class="footer-item-array">
            <div class="footer-item" v-for="(item, index) in footerArray" :key="index"
              :style="{ 'background-image': 'url(' + item.img + ')' }" @click="clickFooter(item)">
              <div class="footer-icon" v-if="item.icon" :style="{ 'background-image': 'url(' + item.icon + ')' }"></div>
            </div>
          </div>
        </div>
      </div>
      <cardModule v-if="cardModuleState"></cardModule>
    </div>
  </div>
</template>

<script setup>
import cardModule from "./cardModule.vue";
import HeaderModule from "./HeaderModule.vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "../stores/pageStore.js";
import { ref } from 'vue'
const downPageStore = useCounterStore();

const {
  cardModuleState,
  footerArray,
  whiteArray,
  publicizeArray,
  videoArray,
  titleArray,
} = storeToRefs(downPageStore);

//结尾跳转
const clickFooter = (item) => {
  window.open(item.path, "_blank");
};

const objectIdPub = ref(null)


const clickItem = (item) => {
  console.log("点击传值", item);
  if (!item.whiteImg) {
    return;
  }
 
  downPageStore.cardModuleState = true;
  downPageStore.cardItem = item;
};


const clickItemPub = (item) => {
  console.log("点击传值", item);
  if (!item.whiteImg) {
    return;
  }
  objectIdPub.value = item.id
  downPageStore.cardModuleState = true;
  downPageStore.cardItem = item;
};

const objectId = ref(null)

const changeMove = (item) => {
  objectId.value = item.id

}

const downOpen = ref(false)

//点击打开
const clickDown=()=>{
  downOpen.value = !downOpen.value
}

const clickUpLoad =()=>{
  downPageStore.upLoadPdf()
}

const LoadRef=ref(false)

const clickLoadButton =()=>{
  LoadRef.value = true
}


</script>
<style scoped>
.page-bg {
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("../assets/white/page-bg-move.jpg");
  background-size: cover;
  background-repeat: no-repeat;

}

.card-open {
  overflow-y: hidden;
}

.card-close {
  overflow-y: auto;
}

.scall {

  overflow-x: hidden;
  max-width: 100vw;
  height: 100vh;
}

.page-main {
  width: 100vw;
  overflow-x: hidden;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5.23rem 0 4.69rem;
}

.main-top-title {
  width: 33.38rem;
  height: 7.19rem;
  background-image: url("../assets/white/move-title-logo.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.white-list {
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.06rem;
}

.video-main {
  margin-top: 1.88rem;
  max-width: 100vw;
  overflow: hidden;
}

.video-list {
  margin-top: 5.23rem;
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-shadow-open{
  text-shadow: 0 0 4px #fff, /* 中间阴影 */
               0 0 8px rgba(255, 178, 117, 1), /* 外层阴影 */
               0 0 10px rgba(255, 178, 117, 1), /* 最外层阴影 */
               0 0 15px rgba(178, 102, 42, 1); /* 最外层阴影的颜色改为紫色 */
  -webkit-text-shadow: 0 0 4px #fff, /* Chrome, Safari, Opera */
                      0 0 8px rgba(255, 178, 117, 1), /* Chrome, Safari, Opera */
                      0 0 10px rgba(255, 178, 117, 1), /* Chrome, Safari, Opera */
                      0 0 15px rgba(178, 102, 42, 1); /* Chrome, Safari, Opera */
  -moz-text-shadow: 0 0 4px #fff, /* Firefox */
                   0 0 8px rgba(255, 178, 117, 1), /* Firefox */
                   0 0 10px rgba(255, 178, 117, 1), /* Firefox */
                   0 0 15px rgba(178, 102, 42, 1); /* Firefox */
}

.publicize-class {
  margin-top: 5.23rem;
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-main {
  margin-top: 7.58rem;
  margin-bottom: 3rem;
  /* width: 32.66rem; */
  height: 5.16rem;
}

.title-main {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  display: flex;
  justify-content: center;
}

.main-title-left {
  width: 1.48rem;
  height: 4.61rem;
  background-image: url("../assets/white/left-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.main-title-right {
  width: 2.66rem;
  height: 4.61rem;
  background-image: url("../assets/white/right-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.title-open {
  color: #fff !important;
}

.title-text {
  font-weight: bold;
  font-size: 2.5rem;
  color: #ffffff;
  line-height: 2.93rem;
  text-align: center;
}

.title-tips-text {
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.8rem;
  text-align: center;
}

.white-list-array {
  width: 54.5rem;
  display: grid;
  grid-template-columns: 1fr;
  /* 设置为1列 */
  grid-template-rows: repeat(5, 6.6rem);
  grid-gap: 1.88rem;
  padding: 1.88rem 0 1.88rem;
}

.white-list-array-close {
  /* grid-template-rows: repeat(6, 6.6rem) !important; */
  grid-template-rows: repeat(5, 6.6rem) !important;
}

.white-list-array-open {
  grid-template-rows: repeat(13, 6.6rem) !important;
}

.upload-main {
  width: 54.5rem;
  height: 6.6rem;
  display: flex;
  justify-content: space-between;
}


.upload-img-main {
  width: 28.67rem;
  height: 8.63rem;
  background-image: url("../assets/white/move-card-upload-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-icon {
  width: 2.19rem;
  height: 2.19rem;
  background-image: url("../assets/white/Down-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 1rem;
  opacity: 0.6;
}
.opcit-open{
  opacity: 1 !important;
}

.upload-text {
  cursor: pointer;
  font-weight: 400;
  font-size: 2.19rem;
  color: #8A8099;
  line-height: 2.56rem;
  text-align: center;
}
.down-main{
  width: 20rem;
  height: 8.63rem;
  display: flex;
  align-items: center;
}
.down-text{
font-weight: 400;
font-size: 2.19rem;
color: #8A8099;
line-height: 2.56rem;
text-align: center;
font-style: normal;
text-transform: none;
}
.down-icon{
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
  background-image: url("../assets/white/drop-down.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.down-icon-open{
  transform: rotate(180deg);
}

.item-main {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-grade-text {
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 4.39rem;
  text-align: center;
  background: linear-gradient(to bottom,
      rgba(152, 103, 255, 1) 0%,
      rgba(206, 103, 255, 0) 100%);
      -webkit-background-clip: text;
  /* Chrome, Safari */
  -moz-background-clip: text;
  /* Firefox */
  background-clip: text;
  color: transparent;
}

.item-grade-text-new{
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 4.39rem;
  text-align: center;
  background: linear-gradient(0.000003805031982181605deg, #FFB275 0%, #B2662A 100%);

  -webkit-background-clip: text;
  /* Chrome, Safari */
  -moz-background-clip: text;
  /* Firefox */
  background-clip: text;
  color: transparent;
}

.item-title-text {
  margin-left: 1.17rem;
  font-weight: bold;
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.19rem;
  text-align: left;
  width: 38rem; /* 设置固定宽度 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的内容 */
  -webkit-line-clamp: 1; /* 针对WebKit浏览器（如Safari、Chrome） */
  -webkit-box-orient: vertical; /* 针对WebKit浏览器（如Safari、Chrome） */
}

.mar-left {
  margin-left: 2.03rem;
}

.item-main-1 {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.publicize-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.arrow-main {
  margin-left: 2rem;
  width: 2.5rem;
  height: 1.2rem;
  transform: translateY(-2rem);
  background-image: url("../assets/white/Pointing arrow.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.main-1-bg {
  width: 30.93rem;
  height: 15.54rem;
  position: absolute;
  bottom: 0;
  background-image: url("../assets/white/card-one-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.lite-item {
  width: 45.78rem;
  height: 27.03rem;
  background: linear-gradient(135deg, #2c1c3e 0%, #100a21 33%);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.16rem solid rgba(52, 31, 80, 1);
  margin: 0 1rem;
}

.video-item {
  width: 44.22rem;
  height: 25.47rem;
}

.video-list-item {

  height: 28.03rem;
  display: grid;
  grid-template-columns: repeat(2, 45.78rem);
  grid-gap: 2.25rem;
  overflow: auto;
}

.slider {

  height: 100%;
  flex: 1;
  display: flex;
}

.carousel {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.carousel-list {
  width: 4.61rem;
  height: 0.39rem;
  display: flex;
  justify-content: space-between;
}

.coordinate-class {
  margin-top: 3.83rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coordinate-item {
  width: 0.78rem;
  height: 0.78rem;
  background-color: #ffffff;
  border-radius: 50%;
}

/* margin-top: 2.03rem;
  display: grid;
  grid-template-rows: repeat(2, 20.4rem);

  grid-template-columns: repeat(2, 25.47rem);
  grid-gap: 1.88rem;
  justify-content: center;
  align-items: center;    */


.publicize-main {
 margin-top: 2.03rem;
  display: grid;
  grid-template-rows: repeat(3, 20.45rem);
  grid-template-columns: repeat(2, 25.47rem);
  grid-gap: 1.88rem;
  justify-content: center;
  align-items: center;   
  /* 设置第二行元素居中显示 */

}

.pub-icon {
  margin-top: 1.95rem;
  width: 5.78rem;
  height: 5.78rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.pub-title {
  margin-top: 1rem;
  width: 80%;
  font-weight: bold;
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.2rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.pub-value {
  width: 21.72rem;
  height: 11.56rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
  font-size: 1.25rem;
  color: #f2f2f2;
  text-align: left;
}

.footer-item-array {
  height: 4.53rem;
  display: grid;
  grid-template-columns: repeat(5, 4.53rem);
  grid-gap: 1.72rem;
}

.footer-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-icon {
  width: 2.81rem;
  height: 2.81rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -0.8rem;
  right: -0.8rem;
}

::-webkit-scrollbar {
  margin-top: 1rem;
  height: 0.2rem;
  width: 0.1rem;
  border-radius: 10px;
  /* 滚动条圆角 */
  /* 设置滚动条的宽度 */
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #050407;
  /* 设置轨道的背景颜色 */
}

/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: #343434;
  /* 设置滑块的背景颜色 */
}
</style>
