//韩语

export default {
    home:{
        title1:'프로젝트 백서',
        title2:'프로젝트 비디오',
        title3:'프로젝트 정보',
        subtitle:'더 많은 업데이트를 기대해주세요',
        content1:'출시 예정',
        content2:'접기',
        content3:'전체 보기',
        content4:'완전한 버전',
        whitePaperTitle0:'투표 결과를 기다리는 중',
        whitePaperTitle1:'개요',
        whitePaperTitle2:'개념과 목표',
        whitePaperTitle3:'소개',
        whitePaperTitle4:'BRC-404-BCP',
        whitePaperTitle5:'사전판매와 에어드랍',
        whitePaperTitle6:'토크노믹스',
        whitePaperTitle7:'Quantum Compass',
        whitePaperTitle8:'Quantum Compute Power',
        whitePaperTitle9:'링 관계 및 수익 배분',
        whitePaperTitle10:'생태계 개발',
        whitePaperTitle11:'SQV 생태계에서의 자치 거버넌스 실천',
        whitePaperTitle12:'로드맵',
        publicizeTitle1:'1 SQV Star Energy Hunter— 오늘 당신의 미래를 만들어라!',
        publicizeTitle2:'2 Soul Quantum Void— 감성과 이성 사이의 미래의 빛',
        publicizeTitle3:'3 “기원의 씨앗” 부화 계획——Soul Quantum Void 프로젝트의 시작을 돕고, 새로운 컨센서스 MEME 생태계의 탄생을 목격하세요.',
        publicizeTitle4:'4 SQV의 핵심 논리 | 토큰 경제학과 수익 능력에 대한 초기 탐구!',
        publicizeTitle5:'5 QCP - 디지털 아트의 마법 박스, 다음 감상 기적을 탐험하세요!',
        publicizeTitle6:'6 관계는 무한하며, SQV 수익이 급상승하는 비밀 무기!',
    }
    
   }
   