// store.js
import { defineStore } from "pinia";

//结尾
import footerImg1 from "@/assets/white/footer-icon-1.png";
import footerImg3 from "@/assets/white/footer-icon-2.png";
import footerImg4 from "@/assets/white/footer-icon-3.png";
import footerImg5 from "@/assets/white/footer-icon-4.png";
import footerIcon1 from "@/assets/white/footer-icon-small-1.png";
import footerIcon2 from "@/assets/white/footer-icon-small-2.png";

import bgimg1 from "@/assets/white/card-bg-long.png";
import bgimg2 from "@/assets/white/card-bg-small.png";

import movebgimgIp from "@/assets/white/move-card-bg-ip.png";
import movebgimg1 from "@/assets/white/move-card-bg-open.png";
// import movebgimg2 from "@/assets/white/move-card-bg-close.png";

//白皮书图片
// import whitePaper1 from "https://assets.vviptuangou.com/800ea1a43864c048ccb747071a4e37891e115679.jpg";
// import whitePaper2 from "@/assets/white/02-Concept and Objectives.png";
// import whitePaper3 from "@/assets/white/03-Introduction.png";
// import whitePaper4 from "@/assets/white/04-BRC-404-BCP Burning Chance Protocol.png";
// import whitePaper5 from "@/assets/white/05-Presale and Airdrop.png";
// import whitePaper6 from "@/assets/white/06-Tokenomics.png";
// import whitePaper7 from "@/assets/white/07-Quantum Compass.png";
// import whitePaper8 from "@/assets/white/08-Quantum Compute Power.png";
// import whitePaper9 from "@/assets/white/09Spherical Relationship Network and Earnings Allocation.png";
// import whitePaper10 from "@/assets/white/10-Ecosystem Development.png";
// import whitePaper11 from "@/assets/white/11-Practice of Autonomous Governance in the SQV Ecosystem.png";
// import whitePaper12 from "@/assets/white/12-RoadMap.png";

//宣传
import publicizebg from "@/assets/white/publicize-bg.png";
import publicizebg1 from "@/assets/white/publicize-bg-1.png";
import pubIcon from "@/assets/white/pub-icon.png";
import Marketing1 from "@/assets/white/Marketing-1.jpg";
import Marketing2 from "@/assets/white/Marketing-2.jpg";
import Marketing3 from "@/assets/white/Marketing-3.jpg";
import Marketing4 from "@/assets/white/Marketing-4.jpg";
import Marketing5 from "@/assets/white/Marketing-5.jpg";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    countDown: {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    },
    cardModuleState: false,
    cardItem: {},
    //结尾数组
    footerArray: [
      {
        id: 1,
        img: footerImg1,
        icon: footerIcon1,
        path: "https://t.me/soulquantumvoid_chat",
      },
      {
        id: 2,
        img: footerImg1,
        icon: footerIcon2,
        path: "https://t.me/soulquantumvoid",
      },
      {
        id: 3,
        img: footerImg3,
        icon: "",
        path: "https://x.com/SQV_Project",
      },
      {
        id: 4,
        img: footerImg4,
        icon: "",
        path: "https://www.youtube.com/@soulquantumvoid",
      },
      {
        id: 5,
        img: footerImg5,
        icon: "",
        path: "https://www.tiktok.com/@soulquantumvoid",
      },
    ],
    //白皮书数组
    whiteArray: [
      {
        id: 0,
        title: "home.whitePaperTitle0",
        grade: "IP",
        bgimg: bgimg1,
        arrow: "",
        movebg: movebgimgIp,
      },
      {
        id: 1,
        title: "home.whitePaperTitle1",
        grade: "01",
        bgimg: bgimg1,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/800ea1a43864c048ccb747071a4e37891e115679.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/c9162eac261aebd75d17e5ac68dfbc2d0ec164e4.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/c91e1aeaccaccd2d3d076acc7b08126cdf56335c.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/1b41b7441056d6f68cb3ae1e0016c2d02356bc4e.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/14d90e3fd32df673c8a63f5d6f74a3a52ba96aa9.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/610e967b97767b41a6a6d68ea116f498.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/eeafcac79c2f1a5329e2dd8319f6481a.png"//俄语图片
      },
      {
        id: 2,
        title: "home.whitePaperTitle2",
        grade: "02",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/36f9c821e065ec532d4d8df544706b308cbf9d88.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/410140d27763d8e479f149533a4a69796ea5c41e.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/9a2ea3b4115693a17af0dcbcc52f57a39eaefb58.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/66f9c6e0bbd999442392223db88d442ce9854555.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/411654aebe8632d2efa1ff0afd5556d5d3ece477.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/e9c51d52f7a5c489adeb8ea03e39f5f9.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/b0ea27b179b74139200963903de6d9e7.png"//俄语图片
      },
      {
        id: 3,
        title: "home.whitePaperTitle3",
        grade: "03",
        bgimg: bgimg1,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/80600540bc7befd618672845b1db05509ebd435a.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/78b59b0349c5eeb56e123fd7009f1e334ce9156b.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/10ea92e95f048346a0cceda86f43e8a3c26d93f8.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/d516790896c6f106fadde236107a5b4aa3fd900a.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/d6810af7f85c412422592509a99b73d7baf6deaf.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/806bd4893d2348ff9b2a716f8b77f255.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/17b8645795a52be4c19ca16e856984db.png"//俄语图片
      },
      {
        id: 4,
        title: "home.whitePaperTitle4",
        grade: "04",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/b4fc56c8017379c9904d79745211e3ddeff62a74.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/41d6e4fc68d22379819490349ca38b218b5f1f7f.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/064b6049ab77c53e3cd3d5d6192a5743ffd7af82.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/4daf569ad70aef12de7ebb6d661a3a0921b2f6a2.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/df57ccc3a3c7655916a7d76dc107fcd541daeeda.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/5486fbd77bdd8ed695eb67466a4f3503.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/b527afe952f10c11587f6e4a25e548c9.png"//俄语图片
      },
      {
        id: 5,
        title: "home.whitePaperTitle5",
        grade: "05",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/b6c819e66f895b8baca4cdc390a3f5251292a1d3.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/510887ea7bf91f74b541359ccc67a3ef6647c0e9.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/06e78289755fc4668ea30092426ff85bafdd81ef.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/879c62064b53f6552fdae16c9bdfa90b26338c63.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/e3aa9bf9ae7d80e273466d534a1a1dc1cbb798e5.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/0be0891a380f9957166011dee15519e3.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/77c94d2978cf64ee7dde00d4c0f18b2b.png"//俄语图片
      },
      {
        id: 6,
        title: "home.whitePaperTitle6",
        grade: "06",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/0256c97a337ff65409e974bc753f0d04f7e43bd6.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/2839f1a1abbfdbbd9d0da283349313b6c61e5d4e.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/cf6fdf34993f60eb5ec0ca3e8ab613979f9ca1f1.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/858105dc68e16fa228cbe9fd5728ace0d5f401c3.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/3927e52c4994a2d8d8e652e963f63baade1f4402.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/2322ebc4202e55ec6fd835571ff9ef25.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/e9bf61b3f278f9ff33c4b3e62f0daa0f.png"//俄语图片
      },
      {
        id: 7,
        title: "home.whitePaperTitle7",
        grade: "07",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/7c0c5626c7f42f70c6d00c4678e27a3630ebdc01.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/2fef2350133bddbf987467f0b7a6ccb9d50b1c99.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/c761e1e5fc5b8e0e529d6a112a7a986be6b7d294.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/442428575138bc4639284c3cf278715d886f54fd.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/c2ae94c646e880874d71cdc3abd56b1160444078.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/365a0734716b86b4a5283debb77252ec.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/fce308baa0034b11c4f22d6e29200e4f.png"//俄语图片
      },
      {
        id: 8,
        title: "home.whitePaperTitle8",
        grade: "08",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/f5350fad17ba1dc1ed698207953db1eb5b293329.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/66df99860d0e528c99b5bc08a72a0e38015b257d.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/a384bd307eb4cb9a87e62823db635c60b60f44ce.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/53f48cc588fde26c5f222e885674bab2320c7dcd.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/d013794ad001666ea81f07cfa12b7469b7015c12.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/0234334661ffb8e19f0186a3f6cedb59.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/482b20dab1837c256a5fbd4978823de1.png"//俄语图片
      },
      {
        id: 9,
        title: "home.whitePaperTitle9",
        grade: "09",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/00cc796ad3b620e0999a686db1f367a111fb6343.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/e5ca4facfdb2a02d170937d5ff4dec6e0b6751e9.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/bc8d55bb55e2d3214028d10f25e4523d7a842d75.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/b1a219429c40c7de265d7bd33baa6ac0ba6ae3b6.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/b98d58a007a31a53cd4ad716f864f06a13fe059a.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/c7479e446a03477f136c840bc5db8f56.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/8df4a88e555c17f833fc4264920708a4.png"//俄语图片
      },
      {
        id: 10,
        title: "home.whitePaperTitle10",
        grade: "10",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/646ad3b506fcd483031fefc1b6074f6c3dee70e7.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/9555a093020be9ed04be4ca16e6a3f0ded08602c.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/8f29827586b0319cd9095ef14ca3cde24be1f6c7.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/b94d437016ad27a4be9d2066667c6ea24b160ceb.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/a9f065045a36c5e1b2ba2b84ff010dd0d931b446.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/c7968b4df0b13f4913a1848ce13ea281.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/4fc6c13b4b93fc5f7ba95e804f19420f.png"//俄语图片
      },
      {
        id: 11,
        title: "home.whitePaperTitle11",
        grade: "11",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/65c2804003012926c9958500fb93611453f6527b.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/bf08f2790670f91f5555cb84a5aaefa8b8dd9b43.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/09e4913c201c95ab4ba53d34ff5dbd0ba3b30656.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/1b98941bb22cc03e85d57f555b64a6171ceb9551.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/3b7f7ad34f06f8e6a0df96e163d25d0db571de62.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/24de90f20ed329bebaf2dfd5483709b9.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/a56b3f2bff847aad3b04891164df8a4d.png"//俄语图片
      },
      {
        id: 12,
        title: "home.whitePaperTitle12",
        grade: "12",
        bgimg: bgimg2,
        arrow: "",
        movebg: movebgimg1,
        whiteImg:"https://assets.vviptuangou.com/9c341ffb8208225cbe45cb4b3ec89d84a3628d16.jpg", //英语图片
        VietnameseImg: "https://assets.vviptuangou.com/f9e9a307470952f0619024a2f4759d0b146ba357.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/ddeae4c7b9a0d5f1b5239a3b57d94ece569ae41d.jpg", //中文简体图片
        TraChineseImg: "https://assets.vviptuangou.com/132affd9752b90889639a4f0754193e9a097cd1d.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/bc4ea0d954527b064d65406da256eb0699745861.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/5ca54a28c29008e6d0a78144e3914222.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/d6bfa7dc067198720a6fa61d30d85689.png"//俄语图片
      },
    ],
    //宣传数组  Marketing4
    publicizeArray: [
      {
        id: 1,
        icon: pubIcon,
        title: "home.publicizeTitle1",
        bgimg: publicizebg1,
        moveBgimg: publicizebg1,
        bgStyle: "",
        whiteImg: Marketing1,
        VietnameseImg: "https://assets.vviptuangou.com/37113ce9888b02a5007f1decd894e13565b67a3b.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/f305f91199454bf611a067d491134292adb08388.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/701d5ee666e9dca664eae7e075f5f7e2725d8c29.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/dbf1422facd20664b5880c4fe5a11224fc78ae9c.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/fd3cbfa293734680c45cd3837855896a.jpg",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/03a3430faece13a166942c6bac9e121f.jpg"//俄语图片
      },
      {
        id: 2,
        icon: pubIcon,
        title: "home.publicizeTitle2",
        bgimg: publicizebg,
        moveBgimg: publicizebg,
        bgStyle: "",
        whiteImg: Marketing2,
        VietnameseImg: "https://assets.vviptuangou.com/aa02dd7147c9183c054f9454cf8ffcaadae8a071.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/94e90e19499b0ab5735c435c457915a93cc1030b.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/499df20d9276088ce58e3c62afbdaa9e8ed1d4b6.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/a044b4af3be80d98ea13538885798a566ad70cea.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/50f5534cb084f5c5ad0bc081739ebe23.jpg",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/f7792af75365105300f89f76b01f742a.jpg"//俄语图片
      },
      {
        id: 3,
        icon: pubIcon,
        title: "home.publicizeTitle3",
        bgimg: publicizebg1,
        moveBgimg: publicizebg1,
        bgStyle: "",
        whiteImg: Marketing3,
        VietnameseImg: "https://assets.vviptuangou.com/f2807d81bfdd1131c84817fef87c749de695fcff.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/f3c5cfc8dab4bd12cc384c7c45b588deaf82e24f.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/c4a0768ae213d0045a2315195e20628fcb9f2e31.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/c9bbf9e8fc1bf95734dd8b2cda4dd27b438ca232.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/79df8b54b5277fc5467b108b95379e87.jpg",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/727e896efdae7fc5490c7439e8f71ba2.jpg"//俄语图片
      },
      
      {
        id: 4,
        icon: pubIcon,
        title: "home.publicizeTitle4",
        bgimg: publicizebg1,
        moveBgimg: publicizebg,
        bgStyle: "",
        whiteImg: Marketing4,
        VietnameseImg: "https://assets.vviptuangou.com/087c7904591107fbbe2eb47f0545f6b93142a0a0.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/9aeba409321f13f78af3e7c962d9066801b3693a.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/9ec6dacdde769384abcc3c229a7155d2de0e97ff.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/e2da360252824797492823650231ace9ee007f75.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/5407f10af8487966d2e9f12d270f775c.jpg",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/9fb548670fca421c6a7b7d4f8c40f681.jpg"//俄语图片
      },
     
      {
        id: 5,
        icon: pubIcon,
        title: "home.publicizeTitle5",
        bgimg: publicizebg,
        moveBgimg: publicizebg1,
        bgStyle: "",
        whiteImg: Marketing5,
        VietnameseImg: "https://assets.vviptuangou.com/95111fda9a6767c1dfcf18a8838271776b4c8293.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/cfdf6d9e91c25aace2587dc0640d2acc6f71c39a.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/55802b6c8e5ef70c16a0760dd59f5686616e0c82.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/7aa4f1b2967b048d0851babdfa834cd8fd7febe9.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/5ef6b1f02d1c84add06a20e879e7472e.jpg",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/b02e9187524ea0595de95c156f55d524.jpg"//俄语图片
      },
      
      {
        id: 6,
        icon: pubIcon,
        title: "home.publicizeTitle6",
        bgimg: publicizebg1,
        moveBgimg: publicizebg,
        bgStyle: "",
        whiteImg: 'https://assets.vviptuangou.com/c65db5d14c1ec781f81fae09c0c62251a633a957.jpg', //英文图片
        VietnameseImg: "https://assets.vviptuangou.com/a65f9208c83370ad29c4ecfe0a6116cb894784f2.jpg", //越南语图片
        SimChineseImg: "https://assets.vviptuangou.com/b5c4646b8884e635dedf876eb214b6277208d423.jpg", //中文简体图片
        TraChineseImg:
          "https://assets.vviptuangou.com/1212148797a440c755752a3a210d7ab86efb6005.jpg", //中文繁体图片
        KoreanImg: "https://assets.vviptuangou.com/7535b0573a3360b4a5966c8ee32351d9075f0d53.jpg", //韩语图片
        ThaiImg: "", //泰语图片
        JpaImg:"https://assets.vviptuangou.com//uploads/touch/20241031/8eb262bda8886d459a900364fdcc6f12.png",//日语图片
        RLImg:"https://assets.vviptuangou.com//uploads/touch/20241106/9b90968a6ac1c19cb4af51e39770c8e8.jpg"//俄语图片
      },
     
    ],
    //视频数组  5 7 8
    videoArray: [
      {
        id: 1, //未来由你定义
        url: "",
        enUrl: "https://www.youtube.com/embed/jGGjJ7FB41k?si=8GxLn6Ck5aHTHR3Y", //英文地址
        VietnameseUrl: "https://www.youtube.com/embed/N2btRhwGKYE?si=f_w6SoflX31do7AJ", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/fmKoD4N3nRg?si=mbfSMU8ecanVMXfL", //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/uGCiwVowHBw?si=9rzDS3o-_mCRWaz6", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/Clmj1r8vOCc?si=JohYzHYY5jriym7S", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/orKAJ1z44zc?si=gLGm45m3A-IvIVLJ",//日语
        RLUrl:"https://www.youtube.com/embed/pHsZIfZDtmQ?si=9iOpMe-3a66_7N1k"//俄语
      },
      {
        id: 2, //生态共识
        url: "",
        enUrl: "https://www.youtube.com/embed/Ijj8IGXDGOI?si=XXJQ7pJ6leaV3GVi",
        VietnameseUrl: "https://www.youtube.com/embed/eh8kGGbcpHU?si=CjIXzmtuaux6vx5D", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/04sKHqmC7tE?si=Ele_9oZR0PAw-ijz", //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/F15gbL1Amik?si=d9Z_Jw4qHegp3-MU", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/gkgpDb2v4kM?si=xQW4NBAOlGYcia4m", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/ntvs5ZbP_m8?si=3QhsOq_SV3KEuWh3",//日语
        RLUrl:"https://www.youtube.com/embed/FVrZjH-A0WU?si=8Ckc2FgD87o9_RfU"//俄语
      },
      {
        id: 3, //科学之钥
        url: "",
        enUrl: "https://www.youtube.com/embed/v1leF0dR4eY?si=-OLSQCOAci4Z6mUy",
        VietnameseUrl: "https://www.youtube.com/embed/38Bx_WiwCwU?si=CgeXpnEgLu909os4", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/HI10mdZlc_8?si=Pk3qrHfb9ac8zKQw" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/32utlOS7qWM?si=FQOQuR-0yDFHbNo2", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/_4TuUCR2xTs?si=9cnJUptg3iw3eDg4", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/5J3QQmJlUw0?si=kq7RKmPCGqA0GHDj",//日语
        RLUrl:"https://www.youtube.com/embed/NQns1mCFzto?si=e4EWVq3iPb9iANrO"//俄语
      },
      {
        id: 4, //起源之种 孵化计划
        url: "",
        enUrl: "https://www.youtube.com/embed/M2TkBIpJfi8?si=6RGMQPcowUsRfwi4",
        VietnameseUrl: "https://www.youtube.com/embed/QvDA2aAratI?si=uQOkdNEy0x0_UXGA", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/hVTsC5iz7s4?si=vI8whhr2Ie80pxMd" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/L__uJEJy2a4?si=VeX_GNzerceArOep", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/wetLGBM4inY?si=HTHYb_wX_S_S7RV4", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/k2pemTi7rec?si=FNV7RM0cb-9lziZv",//日语
        RLUrl:"https://www.youtube.com/embed/WM9DkFBR2FU?si=cB_7kJsrzz21k3T3"//俄语
      },
      {
        id: 5, //预售以及空投
        url: "",
        enUrl: "https://www.youtube.com/embed/NWxAbPoRyR4?si=US3pLIcRJBunHvgq",
        VietnameseUrl: "https://www.youtube.com/embed/LMWvRD5cLBg?si=h4lcb3VrLV605Lo0", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/nFG4QDO4g6s?si=kpRWoWCF1E8OsMHo" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/KZBkV6QwcbI?si=7KwqRWgpwcg2WDZI", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/tfTiy8C0zV4?si=c1nKIWEUxZt2yD79", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/VhpP_J9Fwdw?si=6cFbultjWQ3v-Vt5",//日语
     
        RLUrl:"https://www.youtube.com/embed/QiYTJZ-ANkQ?si=QOD64kPA9LsFYenC"//俄语
      },
      {
        id: 6, //收益系统
        url: "",
        enUrl: "https://www.youtube.com/embed/yhEhA081LYs?si=2q3dZrI-cFLPu71X", //英文地址
        VietnameseUrl: "https://www.youtube.com/embed/2AyC6MMzccs?si=HtePMMdBB70DwqGA", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/r9KFNqAKz6A?si=OkaIyL6VBj7BacH0" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/4C0bZtj8cac?si=h9sVlbXP5xU957uk", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/kjT758ZP1Ew?si=aXqa5biRkjI6sqZ9", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/tWruLQ8nd24?si=atXx-eJvCh677jeK",//日语
        RLUrl:"https://www.youtube.com/embed/qvL449UnNsA?si=Ut3r25puzd44zmcZ"//俄语
      },
      {
        id: 7, //代币经济学
        url: "",
        enUrl: "https://www.youtube.com/embed/p0rhZ_aV6ew?si=5UEfo3kwe_yp5H5W",
        VietnameseUrl: "https://www.youtube.com/embed/HgsjxqfWJQQ?si=Qcklf39pmlY9j3sP", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/4iMNgZhvKAc?si=LwRreRVJPFBJFkMy" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/gOUMMYyhedU?si=NM_ecMDOSlavJoFt", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/Jknb3eyeRgQ?si=nIURxXmbEw9CheEG", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/NWExGE4aXzo?si=VtWchOVdWuMl8iDf",//日语
      
        RLUrl:"https://www.youtube.com/embed/MxpIuFdCvJQ?si=ViQlZZpakW_107n7"//俄语
      },
      {
        id: 8, //量子算力
        url: "",
        enUrl: "https://www.youtube.com/embed/Urkslv6xMdc?si=C4dtlu1eKuyN8nLg", //英文地址
        VietnameseUrl: "https://www.youtube.com/embed/MNsUpUqv_XY?si=SOBGRpV_3ehXL7z6", //越南语
        SimChineseUrl: "https://www.youtube.com/embed/nSaktFWWK_U?si=GkcsuMzoC7u2jrlc" , //中文简体
        TraChineseUrl:
          "https://www.youtube.com/embed/rrYppvaqF2c?si=-PffYmFOxcpiCtCh", //中文繁体
        KoreanUrl: "https://www.youtube.com/embed/iNXLRh69x68?si=fbyazFrAqJQCOPkU", //韩语
        ThaiUrl: "", //泰语
        JpaUrl:"https://www.youtube.com/embed/CDN4IY9NHbY?si=b_gB8pXZrG6qVNEt",//日语
        RLUrl:"https://www.youtube.com/embed/pEFp4ZcktIQ?si=CinisWHqNdJW6QuW"//俄语
      }
    ],
    titleArray: [
      {
        id: 1,
        title: "home.title1",
        tips: "home.subtitle",
      },
      { id: 2, title: "home.title2", tips: "home.subtitle" },
      { id: 3, title: "home.title3", tips: "home.subtitle" },
    ],
  }),
  actions: {
    upLoadPdf() {
      //pdf逻辑部分
      let lan = localStorage.getItem("languageStore");

      if (lan == "cj") {
        //越南语
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmaTzcF5McM7HjKoVcRfBAP52km5vNZ27w6HtdWKfbrvik",
          "_blank"
        );
      }
      if (lan == "en") {
        //英语
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmTcoEGNkukqPRKYt4aJ9mZx8bW6rxHySvvLVNVYv1EiiX",
          "_blank"
        );
      }
      if (lan == "kr") {
        //韩语
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmbR6rUzrxxBCwMW9KAgKrXVjpauFjckyN6hig6hTWFuhp",
          "_blank"
        );
      }
      if (lan == "thai") {
        //泰语
      }
      if (lan == "zh") {
        //中文
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmQTfSvSy7ujABk5UeZeN6eSTaJKJpFCdjRTh4i24W8zVS",
          "_blank"
        );
      }
      if (lan == "zhft") {
        //中文繁体
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmawudT7Ptxwx5VsvHEQjEsSeZUuZL52DEvmyJWcjXctJo",
          "_blank"
        );
      }
      if (lan == "jpa") {
        //日语
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/Qma2qrdJ2c8ofC4KWmUYUHZB8k74nitkQgBZ8hSPZXU9ai",
          "_blank"
        );
      }
      if (lan == "rl") {
        //俄语
        window.open(
          "https://fuchsia-adorable-tick-353.mypinata.cloud/ipfs/QmU61K7jW16YRrvy46R8G7SJjgoXiaWad4TZypqZS9MDz7",
          "_blank"
        );
      }
    },
  },
});
