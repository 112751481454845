export default {
  home: {
    title1: "The Project Whitepaper",
    title2: "Project Video",
    title3: "Project Information",
    subtitle: "More Updates to Follow",
    content1: "Coming Soon",
    content2: "Collapse",
    content3: "View All",
    content4: "Complete Version",
    whitePaperTitle0: "Awaiting Consensus Voting Results",
    whitePaperTitle1: "Overview",
    whitePaperTitle2: "Concept and Objectives",
    whitePaperTitle3: "Introduction",
    whitePaperTitle4: "BRC-404-BCP Burning Chance Protocol",
    whitePaperTitle5: "Presale and Airdrop",
    whitePaperTitle6: "Tokenomics",
    whitePaperTitle7: "Quantum Compass",
    whitePaperTitle8: "Quantum Compute Power",
    whitePaperTitle9: "Spherical Relationship Network and Earnings Allocation",
    whitePaperTitle10: "Ecosystem Development",
    whitePaperTitle11: "Practice of Autonomous Governance in the SQV Ecosystem",
    whitePaperTitle12: "Roadmap",
    publicizeTitle1: "1 SQV Star Energy Hunter— Craft Your Future Today!",
    publicizeTitle2: '2 "Soul Quantum Void—The Future Light Between Sensibility and Rationality',
    publicizeTitle3: '3 "Seed of Origin" Incubation Plan—Boosting the Launch of the Soul Quantum Void Project and Witnessing the Birth of a New Consensus MEME Ecosystem',
    publicizeTitle4: "4 The Core Logic Behind SQV | A Preliminary Exploration of Tokenomics and Earning Power!",
    publicizeTitle5: "5 QCP - The Magic Box of Digital Art, Exploring the Next Appreciation Miracle!",
    publicizeTitle6:"6 Boundless Connections, The Secret Weapon for Soaring Compute Power!",
  },
};
