<template>
 
  <div>
    <HeaderModule></HeaderModule>
    <div class="page-main module-display-1">
    
      <div class="main-top-title"></div>
      <div class="white-list">
        <div class="title-main">
          <div class="main-title">
            <div class="main-title-left"></div>
            <div class="title-text">{{ $t(titleArray[0].title) }}</div>
            <div class="main-title-right"></div>
          </div>
          <div class="title-tips-text">{{ $t(titleArray[0].tips) }}</div>
        </div>
        <div class="white-main" v-if="whiteArray.length>5">
          <div class="array-left-button" @click="clickArrayLeft()"></div>
          <div class="white-list-array">
            <!-- <div v-for="(item, index) in whiteArray.slice(leftNumber - 2, leftNumber)" :key="index"
              @click="clickItem(item)" @mousemove="changeMove(item)">
              <div class="item-main">
                <div class="item-grade-text">{{ item.grade }}</div>
                <div class="item-title-text item-grade-text-width" :class="item.id == objectId ? 'title-open' : ''">{{
              item.title }}</div>
              </div>
            </div> -->
            <div v-for="(items, index) in whiteArray.slice(0, 1)" :key="index"
              @click="clickItem(items)" @mousemove="changeMove(items)"  @mouseleave="leaveLoadWhite()" >
              <div class="item-main-1"   :class="items.id == objectId ? 'filter-open' : ''">
                <div class="main-1-bg"></div>
                <div class="main-1-title">
                  <div class="item-grade-text-new"  :class="items.id == objectId ? 'text-shadow-open' : ''">{{ items.grade }}</div>
                  <div class="item-title-text-new" :class="items.id == objectId ? 'title-open text-shadow-open' : 'title-open'">{{ $t(items.title) }}</div>
                </div>
                <div class="arrow-main-new"></div>
              </div>
            </div>
            <div v-for="(item, index) in whiteArray.slice(leftNumber)" :key="index" @click="clickItem(item)"
              @mousemove="changeMove(item)">
              <div class="item-main" >
                <div class="item-grade-text">{{ item.grade }}</div>
                <div class="item-title-text item-grade-text-width" :class="item.id == objectId ? 'title-open' : ''">{{
             $t(item.title) }}</div>
              </div>
            </div>
          </div>
          <div class="array-right-button" @click="clickArrayRight()"></div>
        </div>

        <div class="white-list-array" v-else>
          <div v-for="(items, index) in whiteArray.slice(0, 1)" :key="index" @click="clickItem(items)"
            @mousemove="changeMove(items)">
            <div class="item-main-1" >
              <div class="main-1-bg"></div>
              <div>
                <div class="item-grade-text">{{ items.grade }}</div>
                <div class="item-title-text" :class="items.id == objectId ? 'title-open' : ''">{{ $t(items.title) }}</div>
              </div>
              <div class="arrow-main"></div>
            </div>
          </div>
          <div v-for="(item, index) in whiteArray.slice(1)" :key="index" @click="clickItem(item)"
            @mousemove="changeMove(item)">
            <div class="item-main">
              <div class="item-grade-text">{{ item.grade }}</div>
              <div class="item-title-text item-grade-text-width" :class="item.id == objectId ? 'title-open' : ''">{{
             $t(item.title) }}</div>
            </div>
          </div>
        </div>
        <div class="upload-main"  v-if="whiteArray.length>5" @click="clickUpLoad()"    @mousemove="changeMoveload()" @mouseleave="leaveLoad()">
          <div class="upload-icon" :class="uploadState?'opcit-open':''" ></div>
          <div class="upload-text" :class="uploadState?'title-open':''">{{ $t('home.content4') }}</div>
        </div>

      </div>
      <div class="video-list">
        <div class="title-main">
          <div class="main-title">
            <div class="main-title-left"></div>
            <div class="title-text">{{ $t(titleArray[1].title) }}</div>
            <div class="main-title-right"></div>
          </div>

          <div class="title-tips-text">{{ $t(titleArray[1].tips) }}</div>
        </div>
        <div class="video-main">
          <div class="video-list-item" v-if="videoArray.length <= 2">
            <div class="lite-item" v-for="(item, index) in videoArray" :key="index">
              <div class="video-item">
                <iframe width="100%" height="100%" :src="item.url" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <el-carousel type="card" :autoplay="false" indicator-position="outside" arrow="never" v-else>
            <el-carousel-item v-for="(item, index) in videoArray" :key="index">
              <div class="video-item">
                <iframe width="100%" height="100%" :src="item.url" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </el-carousel-item>
          </el-carousel>

        </div>
      </div>
      <div class="publicize-class">
        <div class="title-main">
          <div class="main-title">
            <div class="main-title-left"></div>
            <div class="title-text">{{ $t(titleArray[2].title) }}</div>
            <div class="main-title-right"></div>
          </div>

          <div class="title-tips-text">{{ $t(titleArray[2].tips) }}</div>
        </div>
        <div class="publicize-main"  @mouseleave="leaveLoad()">
          <div class="publicize-item" v-for="(item, index) in publicizeArray" :key="index"
            :style="{ 'background-image': 'url(' + item.bgimg + ')' }" @click="clickItem(item)"   @mousemove="changeMovePub(item)">
            <div class="pub-icon" :style="{ 'background-image': 'url(' + item.icon + ')' }"></div>
            <div class="pub-title" :class="item.id == objectPubId ? 'title-open' : ''">{{ $t(item.title)  }}</div>
          </div>
        </div>
      </div>
      <div class="footer-main">
        <div class="footer-item-array">
          <div class="footer-item" v-for="(item, index) in footerArray" :key="index"
            :style="{ 'background-image': 'url(' + item.img + ')' }" @click="clickFooter(item)">
            <div class="footer-icon" v-if="item.icon" :style="{ 'background-image': 'url(' + item.icon + ')' }"></div>
          </div>
        </div>
      </div>
    </div>
    <cardModule v-if="cardModuleState"></cardModule>
  </div>
</template>

<script setup>
import cardModule from "./cardModule.vue";
import HeaderModule from "./HeaderModule.vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "../stores/pageStore.js";
import { ref } from 'vue'
const downPageStore = useCounterStore();



const {
  cardModuleState,
  footerArray,
  whiteArray,
  publicizeArray,
  videoArray,
  titleArray,
} = storeToRefs(downPageStore);

//结尾跳转
const clickFooter = (item) => {
  window.open(item.path, "_blank");
};



const clickItem = (item) => {
  console.log("点击传值", item);
  if (!item.whiteImg) {
    return;
  }
  downPageStore.cardModuleState = true;
  downPageStore.cardItem = item;
};

const objectId = ref(null)

const changeMove = (item) => {
  objectId.value = item.id
  
}


const objectPubId = ref(null)
const changeMovePub = (item) => {
  objectPubId.value = item.id
  
}


//左面数据
const leftNumber = ref(1)


const clickArrayLeft = () => {
  console.log('点击左')
  if( leftNumber.value==1){
    return
  }
  leftNumber.value -= 1
}
const clickArrayRight = () => {
  console.log('点击右')
  if( leftNumber.value==9){
    return
  }
  leftNumber.value += 1
}

const clickUpLoad =()=>{
  downPageStore.upLoadPdf()
}

//下载
const  uploadState= ref(false)

const changeMoveload = ()=>{
  uploadState.value = true
}
const leaveLoad =()=>{
  objectPubId.value = null
  uploadState.value = false
}

const leaveLoadWhite=()=>{
  objectId.value= null
}



</script>
<style scoped>
.page-main {
  max-width: 100vw;
  height: 157.73rem;
  overflow-y: hidden;
  background-image: url("../assets/white/page-bg.jpg");
  background-color: #0a0920;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 9.14rem 0 3.98rem;
}

.main-top-title {
  width: 50.2rem;
  height: 10.78rem;
  background-image: url("../assets/white/title-logo.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.white-list {
  /* height: 22.81rem; */
  height: 28.81rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.video-list {
  height: 39rem;
  width: 93rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.publicize-class {
  /* height: 50rem; */
  width: 93rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-main {
  /* width: 32.66rem; */
  height: 5.16rem;
}

.title-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  display: flex;
  justify-content: center;
}

.main-title-left {
  width: 1.48rem;
  height: 4.61rem;
  background-image: url("../assets/white/left-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.main-title-right {
  width: 2.66rem;
  height: 4.61rem;
  background-image: url("../assets/white/right-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.title-text {
  width: auto;
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 2.81rem;
  color: #ffffff;
  line-height: 3.5rem;
  text-align: center;
}

.title-tips-text {
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.5rem;
  text-align: center;
}

.white-main {
  width: 110rem;
  height: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.white-list-array {
  position: relative;
  height: 12.5rem;
  display: grid;
  grid-template-columns: 27.2rem repeat(4, 14.84rem);
  grid-gap: 1.88rem;
  overflow: hidden;
  grid-template-rows: 1fr;
}

.item-main {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 12.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/white/card-bg-small.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.item-grade-text-width {
  width: 14rem;
}

.item-grade-text {
  position: absolute;
  top: 2rem;
  font-weight: bold;
  font-size: 4.69rem;
  line-height: 4.69rem;
  text-align: center;
  background: linear-gradient(to bottom,
      rgba(152, 103, 255, 1) 0%,
      rgba(206, 103, 255, 0) 100%);
  -webkit-background-clip: text;
  /* Chrome, Safari */
  -moz-background-clip: text;
  /* Firefox */
  background-clip: text;
  /* Standard syntax */
  color: transparent;
}
.item-grade-text-new {
  position: absolute;
  top: 2rem;
  font-weight: bold;
  font-size: 4.69rem;
  line-height: 4.69rem;
  text-align: center;
  background: linear-gradient(to bottom,
  rgba(255, 178, 117, 1) 0%,
  rgba(178, 102, 42, 1) 100%);
  -webkit-background-clip: text;
  /* Chrome, Safari */
  -moz-background-clip: text;
  /* Firefox */
  background-clip: text;
  /* Standard syntax */
  color: transparent;
 
}
.text-shadow-open{
  text-shadow: 0 0 4px #fff, /* 中间阴影 */
               0 0 8px rgba(255, 178, 117, 1), /* 外层阴影 */
               0 0 10px rgba(255, 178, 117, 1), /* 最外层阴影 */
               0 0 15px rgba(178, 102, 42, 1); /* 最外层阴影的颜色改为紫色 */
  -webkit-text-shadow: 0 0 4px #fff, /* Chrome, Safari, Opera */
                      0 0 8px rgba(255, 178, 117, 1), /* Chrome, Safari, Opera */
                      0 0 10px rgba(255, 178, 117, 1), /* Chrome, Safari, Opera */
                      0 0 15px rgba(178, 102, 42, 1); /* Chrome, Safari, Opera */
  -moz-text-shadow: 0 0 4px #fff, /* Firefox */
                   0 0 8px rgba(255, 178, 117, 1), /* Firefox */
                   0 0 10px rgba(255, 178, 117, 1), /* Firefox */
                   0 0 15px rgba(178, 102, 42, 1); /* Firefox */
}

.item-title-text {
  user-select: none;
  position: absolute;
  top: 5.5rem;
  font-weight: bold;
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.3rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -moz-box; /* 针对Firefox浏览器 */
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  display: -ms-flexbox; /* 针对IE浏览器 */
  -ms-flex-line-pack: center;
  -ms-flex-align: center;
}

.item-title-text-new {
  position: absolute;
  top: 5.5rem;
  font-weight: bold;
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.3rem;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 0 1rem 0 0;
}


.item-main-1 {
  width: 100%;
  height: 12.5rem;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url("../assets/white/card-bg-long-new.png");
  background-size: cover;
  background-repeat: no-repeat;

 
}

.filter-open{
  filter: brightness(110%);
}

.publicize-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.arrow-main {
  margin-left: 6rem;
  width: 2.8rem;
  height: 1.25rem;
  transform: translateY(-2rem);
  background-image: url("../assets/white/Pointing arrow.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.arrow-main-new {
  margin-left: 6rem;
  width: 2.8rem;
  height: 1.25rem;
  transform: translateY(-2rem);
 
}

.main-1-bg {
  width: 30.93rem;
  height: 15.54rem;
  position: absolute;
  bottom: 0;
  background-image: url("../assets/white/card-one-bg-new.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.lite-item {
  width: 41.41rem;
  height: 24.45rem;
  background: linear-gradient(135deg, #2c1c3e 0%, #100a21 33%);
  border-radius: 2.34rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.16rem solid rgba(52, 31, 80, 1);
}

.video-main {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
}

.video-item {
  width: 38.91rem;
  height: 21.95rem;
  border-radius: 0.78rem 0.78rem 0.78rem 0.78rem;
}

.video-list-item {
  display: flex;
  justify-content: space-around;
}

.coordinate-class {
  margin-top: 3.83rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coordinate-item {
  width: 0.78rem;
  height: 0.78rem;
  background-color: #ffffff;
  border-radius: 50%;
}

.publicize-main {
  margin-top: 2rem;
  /* height: 19.38rem; */
  display: grid;
  grid-template-rows: repeat(2, 19.38rem);
  grid-template-columns: repeat(3, 24.22rem);
  grid-gap: 1.88rem;
  justify-content: center;
justify-items: center;
}

.pub-icon {
  margin-top: 1.95rem;
  width: 5.78rem;
  height: 5.78rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.pub-title {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.56rem;
  width: 21.8rem;
  color: #8a8099;
  line-height: 2.2rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 4;
 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.footer-item-array {
  height: 5.16rem;
  display: grid;
  grid-template-columns: repeat(5, 5.16rem);
  grid-gap: 1.72rem;
}

.title-open {
  color: #fff !important;
}
.opcit-open{
  opacity: 1 !important;
}

.footer-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}







@keyframes clippath {

  0%,
  100% {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }

  50% {
    clip-path: inset(98% 0 0 0);
  }

  75% {
    clip-path: inset(0 0 0 98%);
  }
}

.footer-icon {
  width: 2.81rem;
  height: 2.81rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -0.8rem;
  right: -0.8rem;
}

.array-left-button {
  cursor: pointer;
  width: 4.06rem;
  height: 4.06rem;
  background-image: url("../assets/white/array-left-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.array-right-button {
  cursor: pointer;
  width: 4.06rem;
  height: 4.06rem;
  background-image: url("../assets/white/array-right-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.upload-main {
  cursor: pointer;
  width: 28.83rem;
  height: 5.63rem;
  background-image: url("../assets/white/card-upload-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-icon {
  width: 2.19rem;
  height: 2.19rem;
  background-image: url("../assets/white/Down-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 1rem;
  opacity: 0.6;
}

.upload-text {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.88rem;
  color: #8a8099;
  line-height: 2.2rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

:deep(.el-carousel__container) {
  height: 24.45rem !important;
  transform: translateX(2rem)
}

:deep(.el-carousel__item) {
  width: 41.41rem;
  height: 24.45rem;
  background: linear-gradient(135deg, #2c1c3e 0%, #100a21 33%);
  border-radius: 2.34rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.16rem solid rgba(52, 31, 80, 1);
}

:deep(.el-carousel__mask) {
  background-color: transparent;
}

:deep(.el-carousel__indicator--horizontal .el-carousel__button) {
  width: 0.78rem !important;
  height: 0.78rem !important;
  margin: 0 0.4rem;
  border-radius: 50%;
}

:deep(.el-carousel__indicators--outside) {
  margin-top: 1.25rem;
}
</style>
