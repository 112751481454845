//越南语
export default {
    home:{
        title1:'Sách trắng của dự án',
        title2:'Video dự án',
        title3:'Thông tin dự án',
        subtitle:'Sẽ có thêm nhiều bản cập nhật mới',
        content1:'Sắp ra mắt',
        content2:'Sụp đổ',
        content3:'Xem tất cả',
        content4:'Phiên bản đầy đủ',
        whitePaperTitle0:'Đang chờ kết quả bỏ phiếu đồng thuận',
        whitePaperTitle1:'Tổng quan',
        whitePaperTitle2:'Khái niệm và Mục tiêu',
        whitePaperTitle3:'Giới thiệu',
        whitePaperTitle4:'BRC-404-BCP Giao thức Burning Chance',
        whitePaperTitle5:'Bán trước và Airdrop',
        whitePaperTitle6:'Kinh tế học Token',
        whitePaperTitle7:'Quantum Compass',
        whitePaperTitle8:'Quantum Compute Power',
        whitePaperTitle9:'Mạng lưới quan hệ hình cầu và  phân bổ thu nhập',
        whitePaperTitle10:'Phát triển hệ sinh thái',
        whitePaperTitle11:'Thực hành quản trị tự chủ trong hệ sinh thái SQV',
        whitePaperTitle12:'Lộ trình',
        publicizeTitle1:'1 SQV Star Energy Hunter—Tạo dựng tương lai của bạn ngay hôm nay!',
        publicizeTitle2:'2 Soul Quantum Void—Ánh sáng tương lai giữa sự nhạy cảm và lý trí',
        publicizeTitle3:'3 Kế hoạch ươm tạo "Hạt giống khởi nguồn"—Thúc đẩy việc ra mắt Dự án Soul Quantum Void và chứng kiến sự ra đời của Hệ sinh thái MEME đồng thuận mới',
        publicizeTitle4:'4 Logic cốt lõi đằng sau SQV | Khám phá sơ bộ về Tokenomics và sức mạnh kiếm tiền!',
        publicizeTitle5:'5 QCP - Chiếc hộp kỳ diệu của nghệ thuật số, khám phá phép màu trân trọng tiếp theo!',
        publicizeTitle6:'6 “Vũ khí bí mật giúp lợi nhuận SQV tăng vọt Mối quan hệ không giới hạn!”',
    }
   
   }
   