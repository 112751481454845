//泰语

export default {
    home:{
        title1:'สมุดปกขาวของโครงการ',
        title2:'วิดีโอของโครงการ',
        title3:'ข้อมูลของโครงการ',
        subtitle:'จะมีการอัปเดตเพิ่มเติมตามมา',
        content1:'เร็วๆ นี้',
        content2:'ยุบ" or "พังทลาย',
        content3:'ดูทั้งหมด',
        content4:'รุ่นสมบูรณ์',
        whitePaperTitle0:'รอผลการลงมติเห็นพ้อง',
        whitePaperTitle1:'ภาพรวม',
        whitePaperTitle2:'แนวคิดและวัตถุประสงค์',
        whitePaperTitle3:'บทนำ',
        whitePaperTitle4:'โปรโตคอลโอกาสการเผา BRC-404-BCP',
        whitePaperTitle5:'การขายล่วงหน้าและการแจกเหรียญ',
        whitePaperTitle6:'เศรษฐศาสตร์โทเคน',
        whitePaperTitle7:'เข็มทิศควอนตัม',
        whitePaperTitle8:'พลังการคอมพิวเตอร์ควอนตัม',
        whitePaperTitle9:'เครือข่ายความสัมพันธ์ทรงกลมและการจัดสรรรายได้',
        whitePaperTitle10:'การพัฒนาระบบนิเวศ',
        whitePaperTitle11:'การปฏิบัติการปกครองอิสระในระบบนิเวศ SQV',
        whitePaperTitle12:'แผนที่เส้นทาง',
        publicizeTitle1:'หลักการพื้นฐานของ SQV | การสำรวจเบื้องต้นเกี่ยวกับเศรษฐศาสตร์โทเคนและพลังการหารายได้!',
        publicizeTitle2:"แผนการบ่มเพาะ 'เมล็ดพันธุ์แห่งต้นกำเนิด'",
        publicizeTitle3:'นักล่าพลังงานดวงดาว SQV— สร้างอนาคตของคุณวันนี้!',
        publicizeTitle4:'Soul Quantum Void—แสงในอนาคตระหว่างความรู้สึกและเหตุผล',
        publicizeTitle5:'QCP - กล่องวิเศษแห่งศิลปะดิจิทัล สำรวจปาฏิหาริย์แห่งการชื่นชมครั้งต่อไป!',
        publicizeTitle6:'标题标题',
    }
    
   }
   