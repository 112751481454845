//日语
export default {
    home:{
        title1:'プロジェクトホワイトペーパー',
        title2:'プロジェクト動画',
        title3:'プロジェクト情報',
        subtitle:'さらなる更新をお楽しみに',
        content1:'近日公開',
        content2:'折りたたむ',
        content3:'すべて表示',
        content4:'完全版',
        whitePaperTitle0:'コンセンサス投票結果を待ってください',
        whitePaperTitle1:'概要',
        whitePaperTitle2:'理念と目標',
        whitePaperTitle3:'紹介',
        whitePaperTitle4:'BRC-404-BCP バーニングチャンスプロトコル',
        whitePaperTitle5:'プレセールとエアドロップ',
        whitePaperTitle6:'トークンエコノミクス',
        whitePaperTitle7:'Quantum Compass',
        whitePaperTitle8:'Quantum Compute Power',
        whitePaperTitle9:'リング関係ネットワークと収益分配',
        whitePaperTitle10:'エコシステムの発展',
        whitePaperTitle11:'SQVエコシステムの自主運用',
        whitePaperTitle12:'Roadmap',
        publicizeTitle1:'1 SQVスターエナジーハンター——未来を定義するのはあなた！',
        publicizeTitle2:'2 Soul Quantum Void—感性と理性の狭間に輝く未来の光',
        publicizeTitle3:'3 「起源の種」孵化プラン—Soul Quantum Voidプロジェクトの始動をサポートし、新しいコンセンサスMEMEエコシステムの誕生を目撃する',
        publicizeTitle4:'4 SQVのコアロジック  トークンエコノミクスと収益能力の初期検討！',
        publicizeTitle5:'5 QCP - デジタルアートの魔法の箱、次なる価値上昇の奇跡を探求！',
        publicizeTitle6:'6 限りないつながり、SQV利益急上昇の秘密兵器',
    }
   
   }
   