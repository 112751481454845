<template>
   
  <HelloWorld v-if="false"/>
  <!-- <CountDown v-else></CountDown> -->

  <whitePaper class="module-display-1"></whitePaper>
  <moveWhitePage class="module-display"></moveWhitePage>
</template>

<script setup>
import HelloWorld from './components/HelloWorld.vue'
import whitePaper from './components/whitePaper.vue'
import moveWhitePage from './components/moveWhitePage.vue'

import {preloadImage} from '@/util/utils'
import { onMounted } from 'vue'

// export default {
//   name: 'App',
//   components: {
//     HelloWorld,
//     whitePaper,
//     moveWhitePage
//   }
// }

onMounted(()=>{
  preloadImage()
  if(!localStorage.getItem('languageStore')){
    localStorage.setItem("languageStore", 'en');
  }
  
})

</script>

<style>

#app {
 
}
@media screen and (max-width: 1101px) {
  .module-display {
    display: block;
  }
  .module-display-1 {
    display: none;
  }
}

@media screen and (min-width: 1101px) {
  .module-display {
    display: none;
  }
  .module-display-1 {
    display: block;
  }
}

</style>
