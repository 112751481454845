//倒计时

import { ref } from "vue";
import { useCounterStore } from "../stores/pageStore.js";

const formatTime = (time) => {
  return time.toString().padStart(2, "0");
};
let timer = ref(null);

export const countdownUtil = (timestamp) => {
  const downPageStore = useCounterStore();

  // 获取当前时间的时间戳
  const now = new Date().getTime();
  // 计算剩余时间（毫秒）
  const remainingTime = timestamp - now;

  if (remainingTime <= 0) {
    console.log("倒计时已结束");
    return;
  }

  // 将剩余时间转换为天、小时、分钟和秒
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  downPageStore.countDown.days = formatTime(days);
  downPageStore.countDown.hours = formatTime(hours);
  downPageStore.countDown.minutes = formatTime(minutes);
  downPageStore.countDown.seconds = formatTime(seconds);
  // 每秒更新倒计时
  clearTimeout(timer.value);
  timer.value = setTimeout(() => countdownUtil(timestamp), 1000);
};

// 示例：倒计时到2023年8月1日0时0分0秒



// 图片预加载
export const preloadImage = async () => {
  const images = {
    "@/assets/white/Marketing-1.jpg": () =>
      import("@/assets/white/Marketing-1.jpg"),
      "@/assets/white/Marketing-2.jpg": () =>
      import("@/assets/white/Marketing-2.jpg"),
      "@/assets/white/Marketing-3.jpg": () =>
      import("@/assets/white/Marketing-3.jpg"),
      "@/assets/white/Marketing-4.jpg": () =>
      import("@/assets/white/Marketing-4.jpg"),
    "@/assets/white/page-bg.jpg": () => import("@/assets/white/page-bg.jpg"),
   
    
  };
  
  for (const path in images) {
    const imageModule = await images[path]();
    const img = new Image();
    img.src = imageModule.default; // 使用 Vite 生成的正确路径
  }
};
