import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import kr from "./kr";
import zhft from "./zhft";
import thai from "./thai";
import cj from "./cj";
import jpa from "./jpa";
import rl from "./rl";
// 可优化点: 使用cookie存储用户选择的语言，第二次进来读取cookie

const i18n = createI18n({
 locale: localStorage.getItem('languageStore')?localStorage.getItem('languageStore'): "en", // 定义默认语言
  legacy: false, //表示不使用旧版 Vue I18n 的模式。
  globalInjection: true, // 表示将国际化实例注入到全局属性中，这样在组件中可以直接访问到 $i18n 对象。
  messages: {
    zh,
    en,
    kr,
    zhft,
    thai,
    cj,
    jpa,
    rl
  },
});

export default i18n;
