<template>
  <div>
    <div class="header-main">
      <div class="header-icon"></div>
      <div class="header-content" @click="changeLanguage()">
        <div class="content-icon"></div>
        <div class="content-lan">{{ language }}</div>
      </div>
      <div class="language-main" v-if="languageCard">
        <div class="main-list" v-for="(item, index) in languageArray" :key="index">
          <div class="list-item" @click="changeItem(item)">{{ item.title }}</div>

        </div>


      </div>
    </div>
  </div>
</template>

<script setup>

import { ref, onMounted } from 'vue';

import { useI18n } from "vue-i18n";

import { useCounterStore } from "../stores/pageStore.js";

const downPageStore = useCounterStore();

const { locale } = useI18n();
const changeLanguage = () => {
  console.log('点击切换语言')
  languageCard.value = !languageCard.value
}

//语言列表
const languageArray = ref([
  {
    id: 0,
    title: "English",
    language: "en",
  },
  {
    id: 1,
    title: "한국어",
    language: "kr",
  },
  {
    id: 2,
    title: "Tiếng Việt",
    language: "cj",
  },
 
  {
    id: 4,
    title: "繁體中文",
    language: "zhft",
  },
  {
    id: 5,
    title: "简体中文",
    language: "zh",
  },
  {
    id: 3,
    title: "日本語",
    language: "jpa",
  },
  {
    id: 6,
    title: "Русский",
    language: "rl",
  },
])

//语言选择框状态
const languageCard = ref(false)
const language = ref('English')

onMounted(() => {
  if (!localStorage.getItem('languageStore')) {
    localStorage.setItem("languageStore", 'en');
  }
  language.value = localStorage.getItem("language") ? localStorage.getItem("language") : 'English';
  getItemUrl()
})



//选择语言
const changeItem = (item) => {
  language.value = item.title;
  localStorage.setItem("language", language.value);
  locale.value = item.language;
  localStorage.setItem("languageStore", locale.value);
  console.log('当前选择的语言--》', locale.value)
  getItemUrl()
  setTimeout(() => {
    languageCard.value = false
  }, 500);
}

//国际化视频
const getItemUrl = () => {
  let lan = localStorage.getItem("languageStore")
  console.log('切换语言',lan)
  if (lan == 'cj') {
    //越南语
    downPageStore.videoArray.forEach((item) => {
      item.url = item.VietnameseUrl
    })
  }
  if (lan == 'en') {
    //英语
    downPageStore.videoArray.forEach((item) => {
    
      item.url = item.enUrl
    })
  }
  if (lan == 'kr') {
    //韩语
    downPageStore.videoArray.forEach((item) => {
      item.url = item.KoreanUrl
    })
  }
  if (lan == 'thai') {
    //泰语
    downPageStore.videoArray.forEach((item) => {
      item.url = item.ThaiUrl
    })
  }
  if (lan == 'zh') {
    //中文
    downPageStore.videoArray.forEach((item) => {
      item.url = item.SimChineseUrl
    })
  }
  if (lan == 'zhft') {
    //中文繁体
    downPageStore.videoArray.forEach((item) => {
      item.url = item.TraChineseUrl
    })
  }
  if (lan == 'jpa') {
    //日语
    downPageStore.videoArray.forEach((item) => {
      item.url = item.JpaUrl
    })
  }
  if (lan == 'rl') {
    //俄语
    downPageStore.videoArray.forEach((item) => {
      item.url = item.RLUrl
    })
  }
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 1101px) {
  .module-display {
    display: block;
  }

  .module-display-1 {
    display: none;
  }

  .header-main {
    width: 100%;
    height: 5.23rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #100B1C;
  }

  .header-icon {
    width: 10.78rem;
    height: 2.5rem;
    background-image: url("../assets/white/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2.5rem;
  }

  .header-content {
    position: relative;
    z-index: 9999;
    cursor: pointer;
    height: 2.81rem;
    margin-right: 2.5rem;
    display: flex;
    align-items: center;
  }

  .content-icon {
    width: 1.88rem;
    height: 1.88rem;
    margin-right: 0.5rem;
    background-image: url("../assets/white/language-icon.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content-lan {
    user-select: none;
    /* Prevent text selection */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    height: 2.81rem;
    font-size: 1.25rem;
    color: #FFFFFF;
    line-height: 2.81rem;
    text-align: center;
  }

  .language-main {
    position: absolute;
    top: 5.23rem;
    left: 0rem;
    z-index: 9999;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #100B1C;
    padding: 1rem 0;
  }

  .main-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 2.5rem;
    color: #F2F2F2;
    line-height: 1.46rem;
    text-align: center;
  }

  .list-item {
    user-select: none;
    /* Prevent text selection */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    width: 80%;
    height: 3rem;
    margin: 1.5rem 0;
  }
}








@media screen and (min-width: 1100px) {
  .module-display {
    display: none;
  }

  .module-display-1 {
    display: block;
  }

  .header-main {
    width: 100%;
    height: 5.23rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #08011C;
    position: relative;
  }

  .header-icon {
    width: 7.06rem;
    height: 1.64rem;
    background-image: url("../assets/white/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 20.31rem;
  }

  .header-content {
    position: relative;
    z-index: 9999;
    cursor: pointer;
    height: 2.81rem;
    margin-right: 20.31rem;
    display: flex;
    align-items: center;
  }

  .content-icon {
    width: 1.88rem;
    height: 1.88rem;
    margin-right: 0.5rem;
    background-image: url("../assets/white/language-icon.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content-lan {
    user-select: none;
    /* Prevent text selection */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    width: 6rem;
    height: 2.81rem;
    font-size: 1.25rem;
    color: #FFFFFF;
    line-height: 2.81rem;
    text-align: left;
  }

  .language-main {
    position: absolute;
    top: 5rem;
    right: 20.31rem;
    z-index: 9999;
    width: 8.91rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #170E29;
    padding: 1rem 0;
  }

  .language-main::after {
    content: "";
    position: absolute;
    top: -0.7rem;
    /* 三角形的位置 */
    left: 50%;
    /* 三角形的位置，根据需要调整 */
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #170e29;
    /* 三角形颜色与盒子背景色相同可使其融合 */
  }

  .main-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1.25rem;
    color: #F2F2F2;
    line-height: 1.46rem;
    text-align: center;
  }

  .list-item {
    user-select: none;
    /* Prevent text selection */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    width: 80%;
    height: 1.8rem;
    margin: 1rem 0;
  }
}
</style>