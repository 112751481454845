//俄语
export default {
    home:{
      title1:'Белая книга',
      title2:'Видео',
      title3:'Информация',
      subtitle:'Ожидайте обновлений',
      content1:'Скоро доступно',
      content2:'Свернуть',
      content3:'Посмотреть все',
      content4:'Полная версия',
      whitePaperTitle0:'Ожидание результата голосования',
      whitePaperTitle1:'Обзор',
      whitePaperTitle2:'Философия и цели',
      whitePaperTitle3:'Введение',
      whitePaperTitle4:'Протокол возможности сжигания BRC-404-BCP',
      whitePaperTitle5:'Предпродажа и Airdrop',
      whitePaperTitle6:'Токеномика',
      whitePaperTitle7:'Quantum Compass',
      whitePaperTitle8:'Quantum Compute Power',
      whitePaperTitle9:'Кольцевая сеть и распределение доходов',
      whitePaperTitle10:'Развитие экосистемы',
      whitePaperTitle11:'Практика самоуправления в экосистеме SQV',
      whitePaperTitle12:'Roadmap',
      publicizeTitle1:'1 SQV «Охотник за звездной энергией» Будущее, которое определяешь ты!',
      publicizeTitle2:'2 Soul Quantum Void Пройти сквозь свет будущего, где соединяются чувства и разум.',
      publicizeTitle3:'3 «Семя Происхождения» Инкубационный план Стимулирование запуска проекта Soul Quantum Void Станьте свидетелем рождения новой экосистемы консенсуса MEME',
      publicizeTitle4:'4 Основные логические принципы SQV',
      publicizeTitle5:'5 QCP--Магический ящик цифрового искусства Искусство поиска следующего чуда прироста ценности!',
      publicizeTitle6:'6 Безграничные связи Секретное оружие для стремительного роста доходов SQV!',
    }
   
   }
   